import React from 'react'
import {Link} from 'gatsby'
import service1 from '../../assets/images/services/service1.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Independant Living Services</h2>
                                    <p>Success Occupational Therapy provides Occupational Therapy and Home modifications services to adults with a wide range of abilities.</p>
                                    <ul className="about-list mb-0">
                                        <li>    
                                            <Link to="/service-assistive-technology">
                                            <i className="flaticon-tick"></i>
                                            Assistive Technology (Equipment)
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/functional-capacity-building">
                                            <i className="flaticon-tick"></i>
                                            Functional Capacity Building and Rehab
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/home-modifications">
                                            <i className="flaticon-tick"></i>
                                            Home Modifications
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/behaviour-support">
                                            <i className="flaticon-tick"></i>
                                            Behaviour Support
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/wheelchair-and-seating">
                                            <i className="flaticon-tick"></i>
                                            Wheelchair and Seating
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/home-safety-assessments">
                                            <i className="flaticon-tick"></i>
                                            Home Safety Assessments
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            
        </React.Fragment>
    )
}

export default OurServices;