import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        What do we do
                    </span>
                    <h2>We provide actionable practical care and advice that is targeted to meet your needs</h2>
                    <p>We work to ensure the best years of your life are spent in your community with your family, friends and neighbours.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/home-remote-visits">
                                    Home and Remote Visits
                                </Link>
                            </h3>
                            <p>When you need help you should'nt have to line up for it.</p>

                            <Link to="/home-remote-visits" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/advocate-for-you">
                                    Advocate for You
                                </Link>
                            </h3>

                            <p>We ensure you get the care you need and are entitled to.</p>
                            
                            <Link to="/advocate-for-you" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/products-and-services">
                                    Deliver Targeted Products and Services that meet your needs.
                                </Link>
                            </h3> 

                            <p>Maintaining your quality of life may require the help of products and services. We will advise you on which ones are suitable for you.</p>
                            
                            <Link to="/products-and-services" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;