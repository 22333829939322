import React from 'react'
import {Link} from 'gatsby'
import ReactWOW from 'react-wow'
import partner1 from '../../assets/images/partner/ndis.png'
import partner2 from '../../assets/images/partner/medicare.png'
import partner3 from '../../assets/images/partner/OTA_logo.png'
import partner4 from '../../assets/images/partner/icwa-logo.png'
import partner5 from '../../assets/images/partner/ahpra_OT.png'
import partner6 from '../../assets/images/partner/dva.png'

const Partner = () => {
    return (
        <div className="partner-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="single-partner-item">
                            <Link 
                                to="https://www.ndis.gov.au/" 
                                target="_blank"
                                activeClassName="active"
                                >
                                   <img src={partner1} alt="National Disability Insurance Scheme" />
                            </Link>    
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                            <Link 
                                to="https://www.servicesaustralia.gov.au/medicare" 
                                target="_blank"
                                activeClassName="active"
                                >   
                                <img src={partner2} alt="Medicare Australia" />
                            </Link>     
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                <Link 
                                to="https://otaus.com.au/" 
                                target="_blank"
                                activeClassName="active"
                                >       
                                    <img src={partner3} alt="Occupational Therapy Australia" />
                                </Link>      
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.4s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                <Link 
                                to="https://www.icwa.wa.gov.au/" 
                                target="_blank"
                                activeClassName="active"
                                >    
                                    <img src={partner4} alt="about" />
                                </Link>    
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.5s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                <Link 
                                to="https://www.ahpra.gov.au/occupational-therapy" 
                                target="_blank"
                                activeClassName="active"
                                >    
                                    <img src={partner5} alt="about" />
                                </Link> 
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.6s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                <Link 
                                to="https://www.dva.gov.au/health-and-treatment/injury-or-health-treatments/health-services/occupational-therapy" 
                                target="_blank"
                                activeClassName="active"
                                >    
                                    <img src={partner6} alt="Vetran Affairs" />
                                </Link>
                                </div>
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>

            <div className="divider"></div>
        </div>
    )
}

export default Partner